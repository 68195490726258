import React, { useState } from 'react';
import {
	Typography,
	Paper,
	Tabs,
	Tab,
	Box,
	Grid,
	Link,
	IconButton,
	Collapse,
	List,
	ListItem,
	ListItemText,
	Chip,
	Tooltip,
	Badge,
	ListItemIcon
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
	Facebook,
	Twitter,
	LinkedIn,
	Instagram,
	YouTube,
	ExpandMore,
	ExpandLess
} from '@material-ui/icons';
import Loading from '../../Loading';
import { renderWebsiteFavicon } from '../../../../helper/listing-info';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 30,
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.palette.background.paper
	},
	tabs: {
		borderBottom: `1px solid #EEEDDF`,
		'& .MuiTab-root': {
			textTransform: 'none',
			minWidth: 0,
			padding: theme.spacing(2, 3),
			fontWeight: theme.typography.fontWeightRegular,
			fontSize: '0.95rem',
			color: '#262626',
			transition: 'all 0.2s ease-in-out',
			'&:hover': {
				backgroundColor: '#F8F7F3',
				color: '#004F2B'
			}
		},
		'& .Mui-selected': {
			fontWeight: theme.typography.fontWeightMedium,
			color: '#004F2B'
		}
	},
	tab: {
		borderBottom: '1.5px solid',
		borderBottomColor: theme.palette.grey['KN_light_grey'],
		fontSize: 'inherit'
	},
	descBox: {
		fontSize: '20px',
		padding: '20px 0 0 50px'
	},
	noActiveTitle: {
		color: theme.palette.grey['KN_light_grey'],
		textAlign: 'center',
		lineHeight: 2
	},
	centerItem: {
		marginTop: 40,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%'
	},
	paper: {
		padding: theme.spacing(3),
		marginBottom: theme.spacing(2),
		borderRadius: theme.spacing(1),
		boxShadow: '0 2px 12px rgba(0, 0, 0, 0.08)',
		transition: 'box-shadow 0.3s ease-in-out',
		'&:hover': {
			boxShadow: '0 4px 20px rgba(0, 0, 0, 0.12)'
		}
	},
	icon: {
		marginRight: theme.spacing(1),
		color: '#004F2B',
		transition: 'transform 0.2s ease-in-out',
		'&:hover': {
			transform: 'scale(1.1)',
			color: '#FF9900'
		}
	},
	chip: {
		margin: theme.spacing(0.5),
		borderRadius: theme.spacing(2),
		backgroundColor: '#EEEDDF',
		color: '#004F2B',
		transition: 'all 0.2s ease-in-out',
		'&:hover': {
			transform: 'translateY(-1px)',
			boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
			backgroundColor: '#004F2B',
			color: '#F8F7F3'
		}
	},
	privateBetaBadge: {
		position: 'absolute',
		top: '10px',
		right: '10px',
		padding: '0 4px',
		height: '16px',
		minWidth: '16px',
		borderRadius: '8px',
		backgroundColor: '#FF9900',
		color: '#262626',
		fontSize: '10px',
		lineHeight: '16px',
		textAlign: 'center',
		whiteSpace: 'nowrap',
		zIndex: 1,
		'& span': {
			padding: '0 4px'
		}
	},
	tabContainer: {
		position: 'relative',
		display: 'inline-flex',
		alignItems: 'center'
	},
	ownerTabContainer: {
		position: 'relative',
		width: '100%'
	},
	betaWarning: {
		backgroundColor: '#FF9900',
		color: '#262626',
		padding: theme.spacing(1.5, 2),
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		borderRadius: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		fontSize: '0.875rem',
		boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)'
	},
	ownerInfoFrame: {
		border: 'none',
		borderRadius: theme.spacing(1.5),
		padding: theme.spacing(3),
		marginTop: theme.spacing(2),
		boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
		backgroundColor: '#F8F7F3'
	},
	sectionTitle: {
		fontWeight: 500,
		marginBottom: theme.spacing(2),
		color: '#004F2B'
	},
	newsLink: {
		textDecoration: 'none',
		color: '#004F2B',
		transition: 'color 0.2s ease-in-out',
		'&:hover': {
			color: '#FF9900',
			textDecoration: 'underline'
		}
	},
	socialMediaContainer: {
		marginTop: theme.spacing(2),
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	tabIndicator: {
		height: 3,
		borderRadius: '3px 3px 0 0',
		backgroundColor: '#004F2B'
	},
	listItem: {
		borderRadius: theme.spacing(1),
		marginBottom: theme.spacing(1),
		transition: 'background-color 0.2s ease-in-out',
		'&:hover': {
			backgroundColor: '#EEEDDF'
		}
	}
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={2}>
					<Box>{children}</Box>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

function a11yProps(index) {
	return {
		id: `scrollable-force-tab-${index}`,
		'aria-controls': `scrollable-force-tabpanel-${index}`
	};
}

const ContactInformation = ({ phones, emails }) => {
	const classes = useStyles();
	return (
		<Grid item xs={12}>
			<Paper className={classes.paper}>
				<Typography variant="h6" className={classes.sectionTitle}>
					Contact Information
				</Typography>
				{phones && phones.length > 0 && (
					<Box display="flex" alignItems="center" flexWrap="wrap" mb={1}>
						<Typography variant="subtitle1" style={{ marginRight: '8px' }}>
							Phones:
						</Typography>
						{phones.map((phone, index) => (
							<Chip key={index} label={phone} className={classes.chip} />
						))}
					</Box>
				)}
				{emails && emails.length > 0 && (
					<Box display="flex" alignItems="center" flexWrap="wrap">
						<Typography variant="subtitle1" style={{ marginRight: '8px' }}>
							Emails:
						</Typography>
						{emails.map((email, index) => (
							<Chip key={index} label={email} className={classes.chip} />
						))}
					</Box>
				)}
			</Paper>
		</Grid>
	);
};

const SocialMedia = ({ profiles }) => {
	const classes = useStyles();
	const renderSocialIcons = (profiles) => {
		const icons = {
			facebook: <Facebook />,
			twitter: <Twitter />,
			linkedin: <LinkedIn />,
			instagram: <Instagram />,
			youtube: <YouTube />
		};

		const genericUrls = [
			'https://www.facebook.com/',
			'https://www.instagram.com/',
			'https://www.linkedin.com/',
			'https://twitter.com/',
			'https://www.youtube.com/'
		];

		return profiles
			.filter((profile) => !genericUrls.includes(profile))
			.map((profile, index) => {
				const platform = Object.keys(icons).find((key) => profile.includes(key));
				return platform ? (
					<Tooltip
						title={platform.charAt(0).toUpperCase() + platform.slice(1)}
						key={index}
					>
						<IconButton href={profile} target="_blank" className={classes.icon}>
							{icons[platform]}
						</IconButton>
					</Tooltip>
				) : null;
			});
	};

	return (
		<Box display="flex" alignItems="center" p={1}>
			{renderSocialIcons(profiles)}
		</Box>
	);
};

const CollapsibleSection = ({ title, expanded, toggleExpand, children }) => {
	const classes = useStyles();

	return (
		<Grid item xs={12}>
			<Paper className={classes.paper}>
				<Typography
					variant="h6"
					onClick={toggleExpand}
					style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
				>
					{title} {expanded ? <ExpandLess /> : <ExpandMore />}
				</Typography>
				<Collapse in={expanded} timeout="auto" unmountOnExit>
					{children}
				</Collapse>
			</Paper>
		</Grid>
	);
};

const OwnerInfoTab = ({ ownerDetailsInformation, ownerInfoLoading }) => {
	const [tabValue, setTabValue] = useState(0);
	const [expandedSections, setExpandedSections] = useState(() => {
		// Initialize with search results expanded if there's no analysis but has search results
		if (ownerDetailsInformation && ownerDetailsInformation.length > 0) {
			const firstOwner = ownerDetailsInformation[0];
			const hasAnalysis =
				firstOwner.AnalysisResults && Object.keys(firstOwner.AnalysisResults).length > 0;
			const hasSearchResults =
				firstOwner.SearchResults && firstOwner.SearchResults.length > 0;

			return {
				searchResults: !hasAnalysis && hasSearchResults,
				social_media: false,
				news: false,
				legal_records: false,
				obituaries: false
			};
		}
		return {};
	});

	const classes = useStyles();

	const handleChange = (event, newValue) => {
		setTabValue(newValue);
		// When changing tabs, check if we need to expand search results for the new tab
		if (ownerDetailsInformation && ownerDetailsInformation[newValue]) {
			const currentOwner = ownerDetailsInformation[newValue];
			const hasAnalysis =
				currentOwner.AnalysisResults &&
				Object.keys(currentOwner.AnalysisResults).length > 0;
			const hasSearchResults =
				currentOwner.SearchResults && currentOwner.SearchResults.length > 0;

			if (!hasAnalysis && hasSearchResults) {
				setExpandedSections((prev) => ({
					...prev,
					searchResults: true
				}));
			}
		}
	};

	const toggleExpand = (key) => {
		setExpandedSections((prev) => ({ ...prev, [key]: !prev[key] }));
	};

	const renderSocialIcons = (profiles) => {
		const icons = {
			facebook: <Facebook />,
			twitter: <Twitter />,
			linkedin: <LinkedIn />,
			instagram: <Instagram />,
			youtube: <YouTube />
		};

		const genericUrls = [
			'https://www.facebook.com/',
			'https://www.instagram.com/',
			'https://www.linkedin.com/',
			'https://twitter.com/',
			'https://www.youtube.com/'
		];

		return profiles
			.filter((profile) => !genericUrls.includes(profile))
			.map((profile, index) => {
				const platform = Object.keys(icons).find((key) => profile.includes(key));
				return platform ? (
					<IconButton key={index} href={profile} target="_blank" className={classes.icon}>
						{icons[platform]}
					</IconButton>
				) : null;
			});
	};

	return (
		<div className={classes.ownerTabContainer}>
			{/* <Badge
				classes={{ badge: classes.privateBetaBadge }}
				badgeContent="PRIVATE BETA"
				color="primary"
			/> */}
			{ownerInfoLoading && <Loading />}
			{!ownerDetailsInformation || ownerDetailsInformation.length === 0 ? (
				<Typography
					variant="h4"
					component="h4"
					className={`${classes.centerItem} ${classes.noActiveTitle}`}
				>
					No additional information is available
				</Typography>
			) : (
				<div>
					<Tabs
						value={tabValue}
						onChange={handleChange}
						aria-label="owner info tabs"
						className={classes.tabs}
						classes={{ indicator: classes.tabIndicator }}
					>
						{ownerDetailsInformation.map((owner, index) => (
							<Tab label={owner.OwnerName} {...a11yProps(index)} key={index} />
						))}
					</Tabs>
					<Paper className={classes.betaWarning}>
						<Typography variant="body2">
							This information is in private beta and may not be fully verified.
						</Typography>
					</Paper>
					{ownerDetailsInformation.map((owner, index) => (
						<TabPanel value={tabValue} index={index} key={index}>
							<Paper className={classes.ownerInfoFrame}>
								<Grid container spacing={2}>
									{owner.AnalysisResults && (
										<>
											{((owner.AnalysisResults.phones &&
												owner.AnalysisResults.phones.length > 0) ||
												(owner.AnalysisResults.emails &&
													owner.AnalysisResults.emails.length > 0)) && (
												<ContactInformation
													phones={owner.AnalysisResults.phones || []}
													emails={owner.AnalysisResults.emails || []}
												/>
											)}

											{owner.AnalysisResults.ethnicity && (
												<Grid item xs={12}>
													<Paper className={classes.paper}>
														<Typography variant="h6">
															Ethnicity
														</Typography>
														<Box
															mt={1}
															display="flex"
															flexWrap="wrap"
															gap={1}
														>
															{Array.isArray(
																owner.AnalysisResults.ethnicity
															) ? (
																owner.AnalysisResults.ethnicity.map(
																	(ethnicity, index) => (
																		<Chip
																			key={index}
																			label={ethnicity}
																			className={classes.chip}
																		/>
																	)
																)
															) : (
																<Chip
																	label={
																		owner.AnalysisResults
																			.ethnicity
																	}
																	className={classes.chip}
																/>
															)}
														</Box>
													</Paper>
												</Grid>
											)}

											{owner.AnalysisResults.social_profiles &&
												owner.AnalysisResults.social_profiles.length >
													0 && (
													<CollapsibleSection
														title="Social Media"
														expanded={expandedSections['social_media']}
														toggleExpand={() =>
															toggleExpand('social_media')
														}
													>
														<SocialMedia
															profiles={
																owner.AnalysisResults
																	.social_profiles
															}
														/>
													</CollapsibleSection>
												)}

											{owner.AnalysisResults.education &&
												owner.AnalysisResults.education.length > 0 && (
													<CollapsibleSection
														title="Education"
														expanded={expandedSections['education']}
														toggleExpand={() =>
															toggleExpand('education')
														}
													>
														<List>
															{owner.AnalysisResults.education.map(
																(edu, index) => (
																	<ListItem
																		key={index}
																		className={classes.listItem}
																	>
																		<ListItemText
																			primary={
																				edu.institution
																			}
																			secondary={`${
																				edu.degree || ''
																			} ${edu.field || ''} ${
																				edu.year || ''
																			}`}
																		/>
																	</ListItem>
																)
															)}
														</List>
													</CollapsibleSection>
												)}

											{owner.AnalysisResults.professional &&
												owner.AnalysisResults.professional.length > 0 && (
													<CollapsibleSection
														title="Professional Experience"
														expanded={expandedSections['professional']}
														toggleExpand={() =>
															toggleExpand('professional')
														}
													>
														<List>
															{owner.AnalysisResults.professional.map(
																(prof, index) => (
																	<ListItem
																		key={index}
																		className={classes.listItem}
																	>
																		<ListItemText
																			primary={prof.type}
																			secondary={prof.details}
																		/>
																	</ListItem>
																)
															)}
														</List>
													</CollapsibleSection>
												)}

											{owner.AnalysisResults.legal_records &&
												owner.AnalysisResults.legal_records.length > 0 && (
													<CollapsibleSection
														title="Legal Records"
														expanded={expandedSections['legal_records']}
														toggleExpand={() =>
															toggleExpand('legal_records')
														}
													>
														<List>
															{owner.AnalysisResults.legal_records.map(
																(record, index) => (
																	<ListItem
																		key={index}
																		className={classes.listItem}
																	>
																		<ListItemText
																			primary={record.type}
																			secondary={`${
																				record.date || ''
																			} - ${
																				record.summary || ''
																			}`}
																		/>
																	</ListItem>
																)
															)}
														</List>
													</CollapsibleSection>
												)}

											{owner.AnalysisResults.obituaries &&
												owner.AnalysisResults.obituaries.length > 0 && (
													<CollapsibleSection
														title="Obituaries"
														expanded={expandedSections['obituaries']}
														toggleExpand={() =>
															toggleExpand('obituaries')
														}
													>
														<List>
															{owner.AnalysisResults.obituaries.map(
																(obit, index) => (
																	<ListItem
																		key={index}
																		className={classes.listItem}
																	>
																		<ListItemText
																			primary={obit.title}
																			secondary={
																				<>
																					<Typography
																						component="span"
																						variant="body2"
																					>
																						{obit.date}
																					</Typography>
																					<br />
																					{obit.summary}
																				</>
																			}
																		/>
																	</ListItem>
																)
															)}
														</List>
													</CollapsibleSection>
												)}

											{owner.AnalysisResults.news &&
												owner.AnalysisResults.news.length > 0 && (
													<CollapsibleSection
														title="News"
														expanded={expandedSections['news']}
														toggleExpand={() => toggleExpand('news')}
													>
														<List>
															{owner.AnalysisResults.news.map(
																(newsItem, index) => (
																	<ListItem
																		key={index}
																		className={classes.listItem}
																	>
																		<ListItemText
																			primary={
																				<a
																					href={
																						newsItem.url
																					}
																					target="_blank"
																					rel="noopener noreferrer"
																					className={
																						classes.newsLink
																					}
																				>
																					{newsItem.title}
																				</a>
																			}
																			secondary={`${newsItem.date} - ${newsItem.summary}`}
																		/>
																	</ListItem>
																)
															)}
														</List>
													</CollapsibleSection>
												)}
										</>
									)}
									{owner.SearchResults && owner.SearchResults.length > 0 && (
										<Grid item xs={12}>
											<Paper className={classes.paper}>
												<Typography
													variant="h6"
													onClick={() => toggleExpand('searchResults')}
												>
													Search Results{' '}
													{expandedSections['searchResults'] ? (
														<ExpandLess />
													) : (
														<ExpandMore />
													)}
												</Typography>
												<Collapse
													in={expandedSections['searchResults']}
													timeout="auto"
													unmountOnExit
												>
													{owner.SearchResults.map(
														(result, resultIndex) => (
															<Paper
																key={resultIndex}
																elevation={0}
																style={{
																	padding: '10px',
																	marginBottom: '20px'
																}}
															>
																<Typography variant="h6">
																	<a
																		href={result.link}
																		target="_blank"
																		rel="noopener noreferrer"
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			textDecoration: 'none'
																		}}
																	>
																		<span>
																			{renderWebsiteFavicon(
																				result.link
																			)}
																		</span>
																		{result.title}
																	</a>
																</Typography>
																<Typography variant="body1">
																	{result.snippet}
																</Typography>
															</Paper>
														)
													)}
												</Collapse>
											</Paper>
										</Grid>
									)}
									<Typography variant="body1">
										Last updated: {owner.LastUpdated}
									</Typography>
								</Grid>
							</Paper>
						</TabPanel>
					))}
				</div>
			)}
		</div>
	);
};

export default OwnerInfoTab;
