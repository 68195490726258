import React, { useMemo, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import DashboardIcon from '@material-ui/icons/Dashboard';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import SearchIcon from '@material-ui/icons/Search';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import ListIcon from '@material-ui/icons/List';
import BarChartIcon from '@material-ui/icons/BarChart';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SettingsIcon from '@material-ui/icons/Settings';
import BusinessIcon from '@material-ui/icons/Business';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import StarBorder from '@material-ui/icons/StarBorder';
import BallotIcon from '@material-ui/icons/Ballot';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'; // outline heart icon
import Collapse from '@material-ui/core/Collapse';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import HomeWork from '@material-ui/icons/HomeWork';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import HouseIcon from '@material-ui/icons/House';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import DirectionsIcon from '@material-ui/icons/Directions';
import ListLink from './ListLink';
import ListButton from './ListButton';
import { useUserState } from '../../../hook/customerHook';
import { checkUserHasPermissionId, logout } from '../../../api/serverRequests';
import { ListSubheader } from '@material-ui/core';
import WebIcon from '@material-ui/icons/Web';

export default function NavList() {
	let page = window.location.href.includes('/user/')
		? window.location.href.split('/user/')[1]
		: window.location.href.split('/').pop();
	const history = useHistory();
	const [open, setOpen] = React.useState(page === 'tagsetting' || page === 'organizationstatus');
	const [openMain, setOpenMain] = React.useState(
		page === 'search' ||
			page === 'search-active' ||
			page === 'search-foreclosure' ||
			page === 'search-priced-dropped'
	);
	const [userState, dispatchUser] = useUserState();
	const [perms, setPerms] = React.useState([]);

	const VIEW_FORECLOSURE_PERM_ID = 11;
	const VIEW_DEAL_PERM_ID = 12;
	const SETTING_ACCESS_PERM_ID = 21;
	const VIEW_OFFMARKET_SEARCH_PERM_ID = 25;
	const VIEW_PLAN_ROUTES_PERM_ID = 17;
	const VIEW_EDIT_HISTORY_PERM_ID = 19;

	const permsToCheck = [
		VIEW_FORECLOSURE_PERM_ID,
		VIEW_DEAL_PERM_ID,
		SETTING_ACCESS_PERM_ID,
		VIEW_OFFMARKET_SEARCH_PERM_ID,
		VIEW_PLAN_ROUTES_PERM_ID,
		VIEW_EDIT_HISTORY_PERM_ID
	];

	React.useEffect(() => {
		// check if user has permissions; Promise.all to return all at once
		if (!userState.permission) {
			Promise.all(
				permsToCheck.map((perm) => {
					return checkUserHasPermissionId(perm, userState.user.user_id)
						.then((res) => {
							if (res.data.success) {
								return perm;
							}
						})
						.catch((err) => {
							console.error(err.message);
						});
				})
			).then((r) => {
				setPerms(r);
				dispatchUser({ permission: r });
			});
		} else {
			//use local session to restore results
			setPerms(userState.permission);
		}
	}, []);

	const handleClickSettings = () => {
		setOpen(!open);
	};

	const handleMainClickSettings = () => {
		setOpenMain(!openMain);
	};

	const handleLogout = () => {
		/* 		dispatchUser({
			isLoggedIn: false,
			propertyClusters: null,
			propertyUrl: null,
			activeFilters: null,
			priceListingFilters: null,
			filters: null,
			advFilters: null,
			checkedLetterhead: false,
			checkedUsername: false,
			routingList: null,
			currentRouteId: null,
			selectedRoutes: null,
			selectedRoutesCheckbox: null
		}); */

		const item = userState;

		for (const key of Object.keys(item)) {
			if ('isLoggedIn' === key || 'checkedLetterhead' === key || 'checkedUsername' === key) {
				userState[key] = false;
			} else userState[key] = null;
		}
		userState['advFilters'] = null;
		userState['mapFilter'] = null;
		userState['userFilters'] = null;
		dispatchUser(item);

		localStorage.clear();

		logout().then((res) => history.push('/login'));
	};

	const mainItems = [
		{
			link: '/user/search',
			label: 'Search',
			page: 'search',
			iconComponent: FindInPageIcon,
			isActive: true,
			isAuth: true,
			isRole: VIEW_OFFMARKET_SEARCH_PERM_ID
		},
		{
			link: '/user/dashboard',
			label: 'Dashboard',
			page: 'dashboard',
			iconComponent: DashboardIcon,
			isActive: true
		},
		{
			link: '/user/task',
			label: 'Task Board',
			page: 'task',
			iconComponent: AssignmentIcon,
			isActive: true
		},
		{
			link: '/user/follow-ups',
			label: 'Follow Ups',
			page: 'follow-ups',
			iconComponent: EventNoteIcon,
			isActive: true
		},
		{
			link: '/user/routes',
			label: 'Routes',
			page: 'routes',
			iconComponent: DirectionsIcon,
			isActive: true,
			isRole: VIEW_PLAN_ROUTES_PERM_ID
		},
		{
			link: '/user/collection',
			label: 'Favorites',
			page: 'collection',
			iconComponent: FavoriteBorderIcon,
			isActive: true
		},
		{
			link: '/user/organization',
			label: 'Organization',
			page: 'organization',
			iconComponent: BusinessIcon,
			isActive: true
		},
		{
			link: '/command-centre',
			label: 'Command Centre',
			page: 'command-centre',
			iconComponent: WebIcon,
			isActive: true
		},
		{
			link: null,
			label: 'Report',
			page: 'report',
			iconComponent: BarChartIcon,
			isActive: false
		},
		{
			link: null, // link: '/user/tour'
			label: 'Tour',
			page: 'tour',
			iconComponent: AddLocationIcon,
			isActive: false
		}
	];

	const activeListings = [
		{
			link: '/user/search-active',
			label: 'Undervalued',
			page: 'search-active',
			iconComponent: WhatshotIcon,
			isActive: true,
			isAuth: true,
			isRole: VIEW_DEAL_PERM_ID
		},
		{
			link: '/user/search-foreclosure',
			label: 'Foreclosure',
			page: 'search-foreclosure',
			iconComponent: AccountBalanceIcon,
			isActive: true,
			isAuth: true,
			isRole: VIEW_FORECLOSURE_PERM_ID
		}
	];
	const secondaryItems = [
		{
			link: '/user/settings',
			label: 'Settings',
			page: 'settings',
			iconComponent: AccountBoxIcon,
			isActive: true,
			isAuth: true,
			isRole: SETTING_ACCESS_PERM_ID
		},
		{
			link: '/user/audit',
			label: 'Audit',
			page: 'audit',
			iconComponent: LibraryAddCheckIcon,
			isActive: true,
			isAuth: true,
			isRole: VIEW_EDIT_HISTORY_PERM_ID
		}
	];

	const logoutItem = {
		link: null,
		label: 'Logout',
		page: null,
		iconComponent: ExitToAppIcon,
		isActive: true,
		hasSubItems: false,
		handleClick: handleLogout
	};

	const subItems = [
		{
			link: '/user/tagsetting',
			label: 'Tags',
			page: 'tagsetting',
			iconComponent: StarBorder,
			isActive: true,
			isAuth: true
		},
		{
			link: '/user/organizationstatus',
			label: 'Status',
			page: 'organizationstatus',
			iconComponent: BallotIcon,
			isActive: true,
			isAuth: true
		}
	];

	const subListItems = useMemo(
		() => (
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{subItems.map(
						(item, idx) =>
							item.isAuth &&
							item.isActive && (
								<ListLink key={idx} navItem={item} selected={page === item.page} />
							)
					)}
				</List>
			</Collapse>
		),
		[page, open]
	);

	const filteredMainItems = mainItems.filter((item) => {
		if (!item.isRole) {
			return true;
		}

		return perms.includes(item.isRole);
	});

	const mainListItems = useMemo(
		() =>
			filteredMainItems.map(
				(item, idx) =>
					item.isActive && (
						<ListLink key={idx} navItem={item} selected={page === item.page} />
					)
			),
		[page, perms]
	);

	const filteredActiveListingsItems = activeListings.filter((item) => {
		if (!item.isRole) {
			return true;
		}

		return perms.includes(item.isRole);
	});

	const activeListingsItems = useMemo(
		() =>
			filteredActiveListingsItems.map(
				(item, idx) =>
					item.isActive && (
						<ListLink key={idx} navItem={item} selected={page === item.page} />
					)
			),
		[page, perms]
	);

	const secondaryListItems = useMemo(
		() =>
			secondaryItems.map((item, idx) => {
				return item.isAuth == null || (item.isAuth && perms.includes(item.isRole)) ? (
					item.link ? (
						<ListLink key={idx} navItem={item} selected={page === item.page} />
					) : (
						<Fragment key={idx}>
							<ListButton
								navItem={item}
								handleClick={item.handleClick}
								isExpanded={open}
							/>
							{item.hasSubItems && subListItems}
						</Fragment>
					)
				) : (
					<></>
				);
			}),
		[page, open, perms]
	);

	const renderLogoutItem = (item) => {
		return (
			<Fragment>
				<ListButton navItem={item} handleClick={item.handleClick} isExpanded={open} />
				{item.hasSubItems && subListItems}
			</Fragment>
		);
	};
	return (
		<>
			<Divider />
			<List>{mainListItems}</List>
			<Divider />
			<List>{activeListingsItems}</List>
			<Divider />
			<List>{secondaryListItems}</List>
			<Divider />
			{renderLogoutItem(logoutItem)}
		</>
	);
}
